import React, { useEffect, useState } from "react";
// import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, onValue, ref } from "firebase/database";

interface CleanUpData {
  date: string;
  group: string;
  todo: string;
}

export default function CleanUp() {
  const [state, setState] = useState<CleanUpData>();
  useEffect(() => {
    const unsubscribe = onValue(
      ref(getDatabase(), "/data/cleanup"),
      (snapshot) => {
        const dbdata = snapshot.val();
        console.log(dbdata);

        //@ts-ignore
        const data: CleanUpData[] = dbdata.map((res) => ({
          date: res.properties["Date"].date?.start ?? "",
          group: res.properties["Group"].select?.name ?? "",
          todo:
            res.properties["ToDo"].rich_text?.map((text: any) => {
              if (text.href === null) return <>{text.plain_text}</>;
              else return <a href={text.href}>{text.plain_text}</a>;
            }) ?? "",
        }));
        setState(data.slice(-1)[0]);
      }
    );
    return unsubscribe;
  }, []);

  return (
    <div id="cleanup" className="row">
      <h2>掃除当番</h2>
      <div>{state?.date}</div>
      <div>{state?.group}</div>
      <div>{state?.todo}</div>
    </div>
  );
}
