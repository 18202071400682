import React from "react";

import Clock from "./items/Clock";
import TrainTimetable from "./items/TrainTimetable";
import CleanUp from "./items/CleanUp";
import Deadline from "./items/Deadline";
import Slack from "./items/Slack";
import Weather from "./items/Weather";

import "./custom.scss";

// const Item = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body1,
//   padding: 10,
// }));

// export const theme = createTheme({
//   typography: {
//     h1: { fontSize: 100, marginTop: 1, Color: "#ffffff" },
//     h2: { fontSize: 48, marginTop: 1 },
//     // h3: { fontSize: 42 },
//     // h4: { fontSize: 36 },
//     // h5: { fontSize: 20 },
//     // h6: { fontSize: 18 },
//     body1: { fontSize: 42 },
//   },
// });

function App() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-9">
            <div className="row">
              <div className="col">
                <Clock />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Deadline />
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <TrainTimetable />
              </div>
              <div className="col-5">
                <CleanUp />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Slack />
              </div>
            </div>
          </div>
          <div className="col-3">
            <Weather />
          </div>
        </div>

        {/* <Grid item xs={12} sm={3}>
            <Item>
              作成中・・・ <br />
              https://lab-info.energylab.jp/
            </Item>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Item></Item>
          </Grid> */}
      </div>
    </>
  );
}

export default App;
