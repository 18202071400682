import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

export default function Slack() {
  const [messages, setMessages] = useState(
    [] as { channel: string; ts: number; text: string }[]
  );

  useEffect(() => {
    const unsubscribe = onValue(
      ref(getDatabase(), "/data/slack/data"),
      (snapshot) => {
        const data = snapshot.val();
        setMessages(data);
      }
    );
    return unsubscribe;
  }, []);

  return (
    <div id="slack">
      <h2>お知らせ</h2>

      {messages &&
        messages.reverse().map((m) => (
          <>
            <h3 className="mb-0">
              {new Date(m.ts * 1000).toLocaleDateString("ja-JP", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </h3>
            <div className="mb-2" id={"message_" + String(m.ts)}>
              {m.text?.replace(/<![^>]*>/g, "") ?? ""}
            </div>
          </>
        ))}
    </div>
  );
}
