// https://ekitan.com/timetable/railway/line-station/202-18/d2?dw=0&view=list
// からコピペして，VScodeで置換する
// (\d.):(\d.).* → $1, $2   で時刻だけを抽出
// (0\d) → $1   でゼロで始まる数字のゼロを削除

export type TrainType = "local" | "exp" | "secexp";

type TimeTable = {
  weekday: { for: string; table: { time: number[]; type: TrainType }[] }[];
  weekend: { for: string; table: { time: number[]; type: TrainType }[] }[];
};

export const timetable: TimeTable = {
  weekday: [
    {
      for: "船橋",
      table: [
        { time: [5, 7], type: "local" },
        { time: [5, 15], type: "local" },
        { time: [5, 25], type: "local" },
        { time: [5, 33], type: "local" },
        { time: [5, 39], type: "local" },
        { time: [5, 44], type: "local" },
        { time: [5, 52], type: "local" },
        { time: [6, 1], type: "local" },
        { time: [6, 8], type: "local" },
        { time: [6, 15], type: "local" },
        { time: [6, 22], type: "local" },
        { time: [6, 30], type: "local" },
        { time: [6, 36], type: "local" },
        { time: [6, 43], type: "local" },
        { time: [6, 49], type: "local" },
        { time: [6, 54], type: "local" },
        { time: [7, 0], type: "local" },
        { time: [7, 6], type: "local" },
        { time: [7, 12], type: "local" },
        { time: [7, 18], type: "local" },
        { time: [7, 24], type: "local" },
        { time: [7, 30], type: "local" },
        { time: [7, 36], type: "local" },
        { time: [7, 41], type: "local" },
        { time: [7, 47], type: "local" },
        { time: [7, 54], type: "local" },
        { time: [8, 1], type: "local" },
        { time: [8, 7], type: "local" },
        { time: [8, 12], type: "local" },
        { time: [8, 17], type: "local" },
        { time: [8, 23], type: "local" },
        { time: [8, 30], type: "local" },
        { time: [8, 39], type: "local" },
        { time: [8, 46], type: "local" },
        { time: [8, 53], type: "local" },
        { time: [9, 0], type: "local" },
        { time: [9, 9], type: "local" },
        { time: [9, 17], type: "local" },
        { time: [9, 29], type: "local" },
        { time: [9, 38], type: "local" },
        { time: [9, 47], type: "local" },
        { time: [9, 55], type: "local" },
        { time: [10, 7], type: "local" },
        { time: [10, 20], type: "local" },
        { time: [10, 27], type: "exp" },
        { time: [10, 30], type: "local" },
        { time: [10, 40], type: "local" },
        { time: [10, 50], type: "local" },
        { time: [10, 57], type: "exp" },
        { time: [11, 0], type: "local" },
        { time: [11, 10], type: "local" },
        { time: [11, 20], type: "local" },
        { time: [11, 27], type: "exp" },
        { time: [11, 31], type: "local" },
        { time: [11, 40], type: "local" },
        { time: [11, 50], type: "local" },
        { time: [11, 57], type: "exp" },
        { time: [12, 0], type: "local" },
        { time: [12, 10], type: "local" },
        { time: [12, 20], type: "local" },
        { time: [12, 27], type: "exp" },
        { time: [12, 30], type: "local" },
        { time: [12, 40], type: "local" },
        { time: [12, 50], type: "local" },
        { time: [12, 57], type: "exp" },
        { time: [13, 0], type: "local" },
        { time: [13, 10], type: "local" },
        { time: [13, 20], type: "local" },
        { time: [13, 27], type: "exp" },
        { time: [13, 30], type: "local" },
        { time: [13, 40], type: "local" },
        { time: [13, 50], type: "local" },
        { time: [13, 57], type: "exp" },
        { time: [14, 0], type: "local" },
        { time: [14, 10], type: "local" },
        { time: [14, 20], type: "local" },
        { time: [14, 27], type: "exp" },
        { time: [14, 30], type: "local" },
        { time: [14, 40], type: "local" },
        { time: [14, 50], type: "local" },
        { time: [14, 57], type: "exp" },
        { time: [15, 0], type: "local" },
        { time: [15, 10], type: "local" },
        { time: [15, 20], type: "local" },
        { time: [15, 27], type: "exp" },
        { time: [15, 30], type: "local" },
        { time: [15, 40], type: "local" },
        { time: [15, 50], type: "local" },
        { time: [15, 58], type: "exp" },
        { time: [16, 0], type: "local" },
        { time: [16, 10], type: "local" },
        { time: [16, 20], type: "local" },
        { time: [16, 29], type: "exp" },
        { time: [16, 33], type: "local" },
        { time: [16, 41], type: "local" },
        { time: [16, 49], type: "local" },
        { time: [16, 56], type: "secexp" },
        { time: [17, 5], type: "local" },
        { time: [17, 12], type: "local" },
        { time: [17, 22], type: "local" },
        { time: [17, 30], type: "secexp" },
        { time: [17, 37], type: "local" },
        { time: [17, 47], type: "local" },
        { time: [17, 52], type: "local" },
        { time: [17, 58], type: "local" },
        { time: [18, 5], type: "local" },
        { time: [18, 14], type: "local" },
        { time: [18, 23], type: "local" },
        { time: [18, 29], type: "local" },
        { time: [18, 35], type: "local" },
        { time: [18, 44], type: "local" },
        { time: [18, 52], type: "local" },
        { time: [18, 59], type: "local" },
        { time: [19, 6], type: "local" },
        { time: [19, 15], type: "local" },
        { time: [19, 22], type: "local" },
        { time: [19, 31], type: "local" },
        { time: [19, 43], type: "local" },
        { time: [19, 53], type: "local" },
        { time: [20, 6], type: "local" },
        { time: [20, 14], type: "local" },
        { time: [20, 23], type: "local" },
        { time: [20, 34], type: "local" },
        { time: [20, 44], type: "local" },
        { time: [20, 54], type: "local" },
        { time: [21, 2], type: "local" },
        { time: [21, 12], type: "local" },
        { time: [21, 21], type: "local" },
        { time: [21, 33], type: "local" },
        { time: [21, 45], type: "local" },
        { time: [21, 55], type: "local" },
        { time: [22, 5], type: "local" },
        { time: [22, 15], type: "local" },
        { time: [22, 27], type: "local" },
        { time: [22, 36], type: "local" },
        { time: [22, 49], type: "local" },
        { time: [23, 1], type: "local" },
        { time: [23, 9], type: "local" },
        { time: [23, 19], type: "local" },
        { time: [23, 36], type: "local" },
        { time: [23, 52], type: "local" },
        { time: [0, 6], type: "local" },
        { time: [0, 18], type: "local" },
        { time: [0, 36], type: "local" },
        { time: [0, 52], type: "local" },
      ],
    },
    {
      for: "大宮",
      table: [
        { time: [5, 26], type: "local" },
        { time: [5, 46], type: "local" },
        { time: [6, 0], type: "local" },
        { time: [6, 13], type: "local" },
        { time: [6, 23], type: "local" },
        { time: [6, 35], type: "local" },
        { time: [6, 47], type: "local" },
        { time: [6, 53], type: "local" },
        { time: [7, 6], type: "local" },
        { time: [7, 15], type: "local" },
        { time: [7, 23], type: "local" },
        { time: [7, 29], type: "local" },
        { time: [7, 41], type: "local" },
        { time: [7, 48], type: "local" },
        { time: [7, 59], type: "local" },
        { time: [8, 10], type: "local" },
        { time: [8, 16], type: "local" },
        { time: [8, 22], type: "local" },
        { time: [8, 30], type: "local" },
        { time: [8, 38], type: "secexp" },
        { time: [8, 45], type: "local" },
        { time: [8, 52], type: "local" },
        { time: [8, 56], type: "local" },
        { time: [9, 4], type: "secexp" },
        { time: [9, 10], type: "local" },
        { time: [9, 16], type: "local" },
        { time: [9, 22], type: "local" },
        { time: [9, 28], type: "local" },
        { time: [9, 35], type: "exp" },
        { time: [9, 41], type: "local" },
        { time: [9, 47], type: "local" },
        { time: [9, 53], type: "local" },
        { time: [10, 6], type: "exp" },
        { time: [10, 15], type: "local" },
        { time: [10, 27], type: "local" },
        { time: [10, 38], type: "local" },
        { time: [10, 48], type: "exp" },
        { time: [10, 58], type: "local" },
        { time: [11, 8], type: "local" },
        { time: [11, 18], type: "exp" },
        { time: [11, 28], type: "local" },
        { time: [11, 38], type: "local" },
        { time: [11, 48], type: "exp" },
        { time: [11, 58], type: "local" },
        { time: [12, 8], type: "local" },
        { time: [12, 18], type: "exp" },
        { time: [12, 28], type: "local" },
        { time: [12, 38], type: "local" },
        { time: [12, 48], type: "exp" },
        { time: [12, 58], type: "local" },
        { time: [13, 8], type: "local" },
        { time: [13, 18], type: "exp" },
        { time: [13, 28], type: "local" },
        { time: [13, 38], type: "local" },
        { time: [13, 48], type: "exp" },
        { time: [13, 58], type: "local" },
        { time: [14, 8], type: "local" },
        { time: [14, 18], type: "exp" },
        { time: [14, 28], type: "local" },
        { time: [14, 38], type: "local" },
        { time: [14, 48], type: "exp" },
        { time: [14, 58], type: "local" },
        { time: [15, 8], type: "local" },
        { time: [15, 18], type: "exp" },
        { time: [15, 28], type: "local" },
        { time: [15, 38], type: "local" },
        { time: [15, 48], type: "exp" },
        { time: [15, 58], type: "local" },
        { time: [16, 8], type: "local" },
        { time: [16, 16], type: "exp" },
        { time: [16, 22], type: "local" },
        { time: [16, 37], type: "local" },
        { time: [16, 49], type: "local" },
        { time: [16, 57], type: "local" },
        { time: [17, 4], type: "local" },
        { time: [17, 14], type: "local" },
        { time: [17, 21], type: "local" },
        { time: [17, 28], type: "local" },
        { time: [17, 35], type: "local" },
        { time: [17, 45], type: "local" },
        { time: [17, 57], type: "local" },
        { time: [18, 6], type: "local" },
        { time: [18, 17], type: "local" },
        { time: [18, 23], type: "local" },
        { time: [18, 27], type: "local" },
        { time: [18, 36], type: "local" },
        { time: [18, 44], type: "local" },
        { time: [18, 51], type: "local" },
        { time: [18, 58], type: "local" },
        { time: [19, 6], type: "local" },
        { time: [19, 14], type: "local" },
        { time: [19, 21], type: "local" },
        { time: [19, 28], type: "local" },
        { time: [19, 36], type: "local" },
        { time: [19, 43], type: "local" },
        { time: [19, 50], type: "local" },
        { time: [19, 58], type: "local" },
        { time: [20, 6], type: "local" },
        { time: [20, 16], type: "local" },
        { time: [20, 26], type: "local" },
        { time: [20, 37], type: "local" },
        { time: [20, 47], type: "local" },
        { time: [20, 56], type: "local" },
        { time: [21, 6], type: "local" },
        { time: [21, 18], type: "local" },
        { time: [21, 26], type: "local" },
        { time: [21, 36], type: "local" },
        { time: [21, 46], type: "local" },
        { time: [21, 56], type: "local" },
        { time: [22, 8], type: "local" },
        { time: [22, 19], type: "local" },
        { time: [22, 28], type: "local" },
        { time: [22, 39], type: "local" },
        { time: [22, 49], type: "local" },
        { time: [23, 1], type: "local" },
        { time: [23, 10], type: "local" },
        { time: [23, 22], type: "local" },
        { time: [23, 35], type: "local" },
        { time: [23, 44], type: "local" },
        { time: [23, 53], type: "local" },
        { time: [0, 5], type: "local" },
        { time: [0, 17], type: "local" },
        { time: [0, 30], type: "local" },
        { time: [0, 40], type: "local" },
        { time: [0, 52], type: "local" },
        { time: [1, 3], type: "local" },
      ],
    },
  ],
  weekend: [
    {
      for: "船橋",
      table: [
        { time: [5, 7], type: "local" },
        { time: [5, 15], type: "local" },
        { time: [5, 25], type: "local" },
        { time: [5, 44], type: "local" },
        { time: [5, 59], type: "local" },
        { time: [6, 7], type: "local" },
        { time: [6, 14], type: "local" },
        { time: [6, 22], type: "local" },
        { time: [6, 28], type: "local" },
        { time: [6, 38], type: "local" },
        { time: [6, 44], type: "local" },
        { time: [6, 53], type: "local" },
        { time: [6, 59], type: "local" },
        { time: [7, 5], type: "local" },
        { time: [7, 14], type: "local" },
        { time: [7, 23], type: "local" },
        { time: [7, 33], type: "local" },
        { time: [7, 37], type: "local" },
        { time: [7, 44], type: "local" },
        { time: [7, 53], type: "local" },
        { time: [8, 3], type: "local" },
        { time: [8, 10], type: "local" },
        { time: [8, 16], type: "local" },
        { time: [8, 28], type: "local" },
        { time: [8, 37], type: "local" },
        { time: [8, 44], type: "local" },
        { time: [8, 53], type: "local" },
        { time: [9, 5], type: "local" },
        { time: [9, 18], type: "local" },
        { time: [9, 28], type: "local" },
        { time: [9, 39], type: "local" },
        { time: [9, 49], type: "local" },
        { time: [9, 56], type: "exp" },
        { time: [10, 0], type: "local" },
        { time: [10, 10], type: "local" },
        { time: [10, 20], type: "local" },
        { time: [10, 27], type: "exp" },
        { time: [10, 30], type: "local" },
        { time: [10, 40], type: "local" },
        { time: [10, 50], type: "local" },
        { time: [10, 57], type: "exp" },
        { time: [11, 0], type: "local" },
        { time: [11, 10], type: "local" },
        { time: [11, 20], type: "local" },
        { time: [11, 27], type: "exp" },
        { time: [11, 31], type: "local" },
        { time: [11, 40], type: "local" },
        { time: [11, 50], type: "local" },
        { time: [11, 57], type: "exp" },
        { time: [12, 0], type: "local" },
        { time: [12, 10], type: "local" },
        { time: [12, 20], type: "local" },
        { time: [12, 27], type: "exp" },
        { time: [12, 30], type: "local" },
        { time: [12, 40], type: "local" },
        { time: [12, 50], type: "local" },
        { time: [12, 57], type: "exp" },
        { time: [13, 0], type: "local" },
        { time: [13, 10], type: "local" },
        { time: [13, 20], type: "local" },
        { time: [13, 27], type: "exp" },
        { time: [13, 30], type: "local" },
        { time: [13, 40], type: "local" },
        { time: [13, 50], type: "local" },
        { time: [13, 57], type: "exp" },
        { time: [14, 0], type: "local" },
        { time: [14, 10], type: "local" },
        { time: [14, 20], type: "local" },
        { time: [14, 27], type: "exp" },
        { time: [14, 30], type: "local" },
        { time: [14, 40], type: "local" },
        { time: [14, 50], type: "local" },
        { time: [14, 57], type: "exp" },
        { time: [15, 0], type: "local" },
        { time: [15, 10], type: "local" },
        { time: [15, 20], type: "local" },
        { time: [15, 27], type: "exp" },
        { time: [15, 30], type: "local" },
        { time: [15, 40], type: "local" },
        { time: [15, 50], type: "local" },
        { time: [15, 57], type: "exp" },
        { time: [16, 0], type: "local" },
        { time: [16, 10], type: "local" },
        { time: [16, 20], type: "local" },
        { time: [16, 27], type: "exp" },
        { time: [16, 30], type: "local" },
        { time: [16, 40], type: "local" },
        { time: [16, 50], type: "local" },
        { time: [16, 58], type: "exp" },
        { time: [17, 1], type: "local" },
        { time: [17, 9], type: "local" },
        { time: [17, 19], type: "local" },
        { time: [17, 31], type: "secexp" },
        { time: [17, 43], type: "local" },
        { time: [17, 54], type: "local" },
        { time: [18, 2], type: "secexp" },
        { time: [18, 11], type: "local" },
        { time: [18, 20], type: "local" },
        { time: [18, 30], type: "secexp" },
        { time: [18, 40], type: "local" },
        { time: [18, 50], type: "local" },
        { time: [19, 0], type: "secexp" },
        { time: [19, 10], type: "local" },
        { time: [19, 20], type: "local" },
        { time: [19, 30], type: "secexp" },
        { time: [19, 40], type: "local" },
        { time: [19, 53], type: "local" },
        { time: [20, 0], type: "secexp" },
        { time: [20, 10], type: "local" },
        { time: [20, 20], type: "local" },
        { time: [20, 30], type: "secexp" },
        { time: [20, 40], type: "local" },
        { time: [20, 50], type: "local" },
        { time: [21, 1], type: "secexp" },
        { time: [21, 15], type: "local" },
        { time: [21, 29], type: "secexp" },
        { time: [21, 39], type: "local" },
        { time: [21, 50], type: "local" },
        { time: [22, 3], type: "local" },
        { time: [22, 17], type: "local" },
        { time: [22, 32], type: "local" },
        { time: [22, 47], type: "local" },
        { time: [23, 2], type: "local" },
        { time: [23, 16], type: "local" },
        { time: [23, 30], type: "local" },
        { time: [23, 46], type: "local" },
        { time: [0, 0], type: "local" },
        { time: [0, 18], type: "local" },
      ],
    },
    {
      for: "大宮",
      table: [
        { time: [5, 26], type: "local" },
        { time: [5, 46], type: "local" },
        { time: [6, 6], type: "local" },
        { time: [6, 21], type: "local" },
        { time: [6, 36], type: "local" },
        { time: [6, 45], type: "local" },
        { time: [6, 55], type: "local" },
        { time: [7, 5], type: "secexp" },
        { time: [7, 15], type: "local" },
        { time: [7, 23], type: "local" },
        { time: [7, 35], type: "local" },
        { time: [7, 44], type: "local" },
        { time: [7, 53], type: "local" },
        { time: [8, 6], type: "secexp" },
        { time: [8, 14], type: "local" },
        { time: [8, 21], type: "local" },
        { time: [8, 27], type: "local" },
        { time: [8, 36], type: "secexp" },
        { time: [8, 45], type: "local" },
        { time: [8, 54], type: "local" },
        { time: [9, 3], type: "local" },
        { time: [9, 11], type: "secexp" },
        { time: [9, 21], type: "local" },
        { time: [9, 33], type: "local" },
        { time: [9, 40], type: "local" },
        { time: [9, 48], type: "exp" },
        { time: [10, 5], type: "local" },
        { time: [10, 18], type: "exp" },
        { time: [10, 28], type: "local" },
        { time: [10, 38], type: "local" },
        { time: [10, 48], type: "exp" },
        { time: [10, 58], type: "local" },
        { time: [11, 8], type: "local" },
        { time: [11, 18], type: "exp" },
        { time: [11, 28], type: "local" },
        { time: [11, 38], type: "local" },
        { time: [11, 48], type: "exp" },
        { time: [11, 58], type: "local" },
        { time: [12, 8], type: "local" },
        { time: [12, 18], type: "exp" },
        { time: [12, 28], type: "local" },
        { time: [12, 38], type: "local" },
        { time: [12, 48], type: "exp" },
        { time: [12, 58], type: "local" },
        { time: [13, 8], type: "local" },
        { time: [13, 18], type: "exp" },
        { time: [13, 28], type: "local" },
        { time: [13, 38], type: "local" },
        { time: [13, 48], type: "exp" },
        { time: [13, 58], type: "local" },
        { time: [14, 8], type: "local" },
        { time: [14, 18], type: "exp" },
        { time: [14, 28], type: "local" },
        { time: [14, 38], type: "local" },
        { time: [14, 48], type: "exp" },
        { time: [14, 58], type: "local" },
        { time: [15, 8], type: "local" },
        { time: [15, 18], type: "exp" },
        { time: [15, 28], type: "local" },
        { time: [15, 38], type: "local" },
        { time: [15, 48], type: "exp" },
        { time: [15, 58], type: "local" },
        { time: [16, 8], type: "local" },
        { time: [16, 18], type: "local" },
        { time: [16, 28], type: "local" },
        { time: [16, 38], type: "local" },
        { time: [16, 48], type: "local" },
        { time: [16, 56], type: "local" },
        { time: [17, 8], type: "local" },
        { time: [17, 18], type: "secexp" },
        { time: [17, 24], type: "local" },
        { time: [17, 33], type: "local" },
        { time: [17, 41], type: "local" },
        { time: [17, 48], type: "secexp" },
        { time: [17, 55], type: "local" },
        { time: [18, 8], type: "local" },
        { time: [18, 18], type: "secexp" },
        { time: [18, 27], type: "local" },
        { time: [18, 39], type: "local" },
        { time: [18, 49], type: "secexp" },
        { time: [18, 59], type: "local" },
        { time: [19, 9], type: "local" },
        { time: [19, 19], type: "secexp" },
        { time: [19, 29], type: "local" },
        { time: [19, 39], type: "local" },
        { time: [19, 49], type: "secexp" },
        { time: [19, 59], type: "local" },
        { time: [20, 9], type: "local" },
        { time: [20, 21], type: "secexp" },
        { time: [20, 29], type: "local" },
        { time: [20, 39], type: "local" },
        { time: [20, 49], type: "local" },
        { time: [20, 59], type: "local" },
        { time: [21, 8], type: "local" },
        { time: [21, 19], type: "local" },
        { time: [21, 29], type: "local" },
        { time: [21, 38], type: "local" },
        { time: [21, 50], type: "local" },
        { time: [22, 3], type: "local" },
        { time: [22, 18], type: "local" },
        { time: [22, 34], type: "local" },
        { time: [22, 48], type: "local" },
        { time: [23, 3], type: "local" },
        { time: [23, 21], type: "local" },
        { time: [23, 32], type: "local" },
        { time: [23, 46], type: "local" },
        { time: [23, 59], type: "local" },
        { time: [0, 17], type: "local" },
        { time: [0, 30], type: "local" },
        { time: [0, 40], type: "local" },
      ],
    },
  ],
};
