import React, { useEffect, useState } from "react";
import moment from "moment";

export default function Clock() {
  const [now, setNow] = useState(moment());

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setNow(moment());
    }, 1000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [now]);

  const japaneseWeek = ["日", "月", "火", "水", "木", "金", "土"];

  const date = now.format("YYYY-MM-DD"); // 年-月-日
  const dayOfWeek = japaneseWeek[Number(now.format("d"))]; // 曜日
  const time = now.format("HH:mm:ss"); // 時:分:秒

  // 年-月-日 (曜日) 時:分:秒
  return (
    <div id="clock">
      <span id="date" className="me-5">
        {date} ({dayOfWeek})
      </span>
      <span id="time">{time}</span>
    </div>
  );
}
