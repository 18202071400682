import React, { useEffect, useState } from "react";
// import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, onValue, ref } from "firebase/database";
import moment from "moment-timezone";

interface DeadlineData {
  icon: string;
  title: string;
  deadline: string;
  days: number;
}

export default function CleanUp() {
  const [state, setState] = useState<DeadlineData[]>();
  useEffect(() => {
    const unsubscribe = onValue(
      ref(getDatabase(), "/data/deadline"),
      (snapshot) => {
        const dbdata = snapshot.val();
        const today = moment().tz("Asia/Tokyo");

        //@ts-ignore
        const data: DeadlineData[] = dbdata.map((res) => {
          const deadline = moment(res.properties["Deadline"].date?.start);
          const diff = deadline.diff(today, "days") + 1;
          return {
            icon: res.icon?.emoji ?? "",
            title: res.properties["Name"].title?.[0].plain_text ?? "",
            deadline: deadline.format("MM月DD日"),
            days: diff,
          };
        });
        setState(data);
      }
    );
    return unsubscribe;
  }, []);

  return (
    <>
      {state?.map((d) => (
        <div className="row">
          <div className="col-6 deadline">
            {d?.icon}
            {d?.title}
          </div>
          <div className="col-6 text-end text-secondary">
            〆切 ({d?.deadline}) まであと
            <span className="deadlinedays">{d?.days}</span>日
          </div>
        </div>
      ))}
    </>
  );
}
