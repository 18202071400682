import React, { useEffect, useState } from "react";
import moment from "moment";
import { timetable } from "../data/timetable";
import { getDatabase, ref, onValue, set } from "firebase/database";

const bellSound = new Audio("bell.mp3");

export default function TrainTimetable() {
  const [now, setNow] = useState(moment());
  const [nextTime, setNextTime] = useState(
    [] as {
      for: string;
      table: { hour: number; minute: number; type: string }[];
    }[]
  );

  const meetingTime = ["", "10:00", "10:30", "11:00"];
  const background = [
    "",
    "bg-success bg-gradient",
    "bg-secondary bg-gradient",
    "bg-danger bg-gradient",
  ];
  const [meeting, setMeeting] = useState(0);
  const [blinking, setBlinkng] = useState(false);

  const resetMeeting = () => {
    const db = getDatabase();
    const meetingRef = ref(db, "/data/meeting");
    set(meetingRef, 0);
  };

  useEffect(() => {
    const unsubscribe = onValue(
      ref(getDatabase(), "/data/meeting"),
      (snapshot) => {
        const newValue = snapshot.val();
        setMeeting(newValue);
      }
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    setBlinkng(true);
    setTimeout(() => setBlinkng(false), 30000);
    if (meeting !== 0) {
      bellSound.currentTime = 0;
      bellSound.play();
    }
  }, [meeting]);

  const handleClick = () => {
    //write to realtime database
    const db = getDatabase();
    const meetingRef = ref(db, "/data/meeting");
    set(meetingRef, (meeting + 1) % meetingTime.length);
  };

  useEffect(() => {
    const timeoutId = setInterval(() => {
      const mmt = moment();
      setNow(mmt);
      const weekdayOrWeekend = [0, 6].includes(mmt.day())
        ? "weekend"
        : "weekday"; // 平日, 休日 の 判定
      const hour = mmt.hour();
      const minute = mmt.minute();

      const MAX_PREVIEW_LENGTH = 4; // 発車時刻の表示数(最大)
      setNextTime(
        timetable[weekdayOrWeekend].map((t) => ({
          for: t.for,
          table: t.table.reduce((prev, next) => {
            if (
              ((next.time[0] === hour && next.time[1] >= minute) ||
                next.time[0] > hour) &&
              prev.length < MAX_PREVIEW_LENGTH
            ) {
              return [
                ...prev,
                { hour: next.time[0], minute: next.time[1], type: next.type },
              ];
            } else return prev;
          }, [] as { hour: number; minute: number; type: string }[]),
        }))
      );

      // 12時以降で10分毎にミーティングをリセット(ここに書くのは本当は良くないが・・・)
      if (meeting === 0 && hour >= 12 && minute % 10 === 0) {
        resetMeeting();
      }
    }, 1000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [now, meeting]);

  if (meeting === 0) {
    // meeting == 0 のとき 時刻表を表示
    return (
      <div id="train-timetable" onClick={() => handleClick()}>
        <h2>時刻表</h2>
        {nextTime.map((b) => (
          <div>
            <span className="for me-2">{b.for}方面</span>
            {b.table.map((t) => (
              <span
                className="time me-2"
                style={{ backgroundColor: getColor(t.type) }}
              >
                {String(t.hour).padStart(2, "0")}:
                {String(t.minute).padStart(2, "0")}
              </span>
            ))}
          </div>
        ))}
      </div>
    );
  } else {
    // meeting != 0 のとき ミーティング情報を表示
    return (
      <div
        id="train-timetable"
        className={
          background[meeting] + " text-light" + (blinking ? " blinking" : "")
        }
        onClick={() => handleClick()}
      >
        <h2>ミーティング</h2>
        <div>{meetingTime[meeting]}</div>
      </div>
    );
  }
}

function getColor(type: string) {  // 背景色の設定
  if (type === "exp") return "#ff000099"; // 背景色 red (透明度 62.50%)
  if (type === "secexp") return "#ff8c00a9"; // 背景色 darkorange (透明度 68.75%)
  return "#ffffffff";  // 背景色 white (透明度 100.00%)
}
