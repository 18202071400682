import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

interface WeatherType {
  weatherIconList: string[];
  localTemp: string[];
  localTempMin: string[];
  localTempMax: string[];
  weekday: string[];
}

export default function Weather() {
  const [weather, setWeather] = useState({
    weatherIconList: [],
    localTemp: [],
    localTempMin: [],
    localTempMax: [],
    weekday: [],
  } as WeatherType);

  useEffect(() => {
    const unsubscribe = onValue(
      ref(getDatabase(), "/data/weather"),
      (snapshot) => {
        setWeather(snapshot.val() as WeatherType);
      }
    );
    return unsubscribe;
  }, []);

  return (
    <table id="weather">
      <tr className="p-1">
        <td className="date" align="right">
          本日
        </td>
        <td>
          <img
            src={`/weathericon/${weather.weatherIconList[0]}.png`}
            alt={weather.weatherIconList[0]}
          />
        </td>
        <td>
          <span className="high">{weather.localTemp[1]}℃</span>
          <span className="low">{weather.localTemp[0]}℃</span>
        </td>
      </tr>
      <tr className="p-1">
        <td className="date" align="right">
          明日
        </td>
        <td>
          <img
            src={`/weathericon/${weather.weatherIconList[1]}.png`}
            alt={weather.weatherIconList[1]}
          />
        </td>
        <td>
          <span className="high">{weather.localTemp[3]}℃</span>
          <span className="low">{weather.localTemp[2]}℃</span>
        </td>
      </tr>
      {weather.weekday.map((day, i) => (
        <tr className="p-1">
          <td className="date" align="right">
            {day}
          </td>
          <td>
            <img
              src={`/weathericon/${weather.weatherIconList[i + 2]}.png`}
              alt={weather.weatherIconList[1]}
            />
          </td>
          <td>
            <span className="high">{weather.localTempMax[i]}℃</span>
            <span className="low">{weather.localTempMin[i]}℃ </span>
          </td>
        </tr>
      ))}
    </table>
  );
}
