import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";

export function initFirebase() {
  initializeApp({
    apiKey: "AIzaSyBU3xdYHIgzf1hga_8-1TfgkqHr9CF3Wlc",
    authDomain: "lab-info-3672f.firebaseapp.com",
    projectId: "lab-info-3672f",
    storageBucket: "lab-info-3672f.appspot.com",
    messagingSenderId: "743707157646",
    appId: "1:743707157646:web:b9f475b1563ca775d755df",
    measurementId: "G-FXPMGKJ2GN",
    databaseURL: "https://lab-info-3672f-default-rtdb.firebaseio.com",
  });

  const enableEmulators = () => {
    const db = getDatabase();
    connectDatabaseEmulator(db, "localhost", 9000);
    const functions = getFunctions();
    connectFunctionsEmulator(functions, "localhost", 5001);
  };

  const runningOnJest = typeof window !== "object";
  if (runningOnJest) {
    // Jest
    enableEmulators();
  } else {
    // Emulator
    const runningOnLocalhost = window.location.hostname === "localhost";
    if (runningOnLocalhost) {
      enableEmulators();
    }
  }
}
